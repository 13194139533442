import React, { forwardRef, useEffect, useRef, ForwardedRef } from 'react';
import NativeScene from '@bycape/scenejs';
import { ScenePropTypes } from './types';

export const Scene = forwardRef(
    (
        {
            children,
            ready = true,
            keyframes,
            css = false,
            time = -1,
            autoplay = false,
            duration,
            fillMode,
            iterationCount,
            onPlay = () => undefined,
            onPaused = () => undefined,
            onEnded = () => undefined,
            onTimeUpdate = () => undefined,
            onIteration = () => undefined,
            onAnimate = () => undefined,
            ...sceneOptions
        }: ScenePropTypes,
        ref: ForwardedRef<NativeScene>
    ) => {
        const itemRef = useRef<NativeScene>(new NativeScene({}, { selector: true }));
        const readyRef = useRef(false);

        // Forward the ref to parent
        useEffect(() => {
            if (ref) {
                if (typeof ref === 'function') {
                    ref(itemRef.current);
                } else {
                    ref.current = itemRef.current;
                }
            }
        }, [ref]);

        const init = () => {
            if (!ready || readyRef.current) {
                return;
            }

            readyRef.current = true;
            const item = itemRef.current;

            if (keyframes) {
                item.load(keyframes);
            }

            item.setOptions({
                duration,
                fillMode,
                iterationCount,
                ...sceneOptions
            });

            // Setup event listeners
            item.on('play', onPlay);
            item.on('paused', onPaused);
            item.on('ended', onEnded);
            item.on('timeupdate', onTimeUpdate);
            item.on('iteration', onIteration);
            item.on('animate', onAnimate);

            if (autoplay) {
                css ? item.playCSS() : item.play();
            } else if (time !== -1) {
                item.setTime(time);
            } else {
                item.setTime(0);
            }
        };

        useEffect(() => {
            init();
            return () => {
                itemRef.current.off();
            };
        }, [ready]);

        useEffect(() => {
            if (time !== -1 && (autoplay === false || itemRef.current.getPlayState() === 'paused')) {
                itemRef.current.setTime(time);
            }
        }, [time, autoplay]);

        return <>{children}</>;
    }
);

Scene.displayName = 'Scene';
